import { FC } from 'react'
import FormCreation from '../../../../components/Common/ModalDetails/FormCreation'
import { FormCreationType, StatusAPI, TableType } from '../../../../enum'
import SectionLayout from '../../stockscreen/component/SectionLayout'
import { Form, Input } from 'antd'
import CustomSearch from '../../../../components/Common/CustomSearch/CustomSearch'
import destinataireApi from '../../../../http/destinataireApi'
import Table, { ColumnsType } from 'antd/es/table'
import { Action } from './FormLivraison'
import { NameAndValue } from '../../stockscreen/component/Wrapper'
import { Adresses, Contact } from '../../../../models'
import { renderNull } from '../../../../utils'
import InputRadio from '../../../../components/Common/ModalDetails/InputRadio'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { focusById } from '../../../../hook/usePressTab'

const FormDestination: FC<{
  isCreateDestinataire: boolean
  setIsCreateDestinataire: (val: boolean) => void
  goToEmptyDestinataire?: any
  isEmptyDestinataire: boolean
  onChangeDestinataire: (val: NameAndValue, code: string) => void
  currentTransporteur: any
  form: any
  currentDestinataire: any
  dataDestinataireAdresses: Adresses[]
  dataDestinataireContact: Contact[]
  handleAttDestinataire: (type: string, value: any) => void
  displayRelay: boolean
  isDisabled?: boolean
}> = ({
  isCreateDestinataire,
  setIsCreateDestinataire,
  goToEmptyDestinataire,
  isEmptyDestinataire,
  onChangeDestinataire,
  currentTransporteur,
  form,
  currentDestinataire,
  dataDestinataireAdresses,
  dataDestinataireContact,
  handleAttDestinataire,
  displayRelay,
  isDisabled = false,
}) => {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const columnsAdresses: ColumnsType<Adresses> = [
    {
      title: 'Adresse',
      dataIndex: 'addresse',
      key: 'addresse',
      width: 370,
      render: (_, row) =>
        `${renderNull(row.addresse as string)} - ${renderNull(
          row.code_postal as string
        )} - ${renderNull(row.ville as string)} - ${renderNull(
          row.pays as string
        )} (${renderNull(row.iso as string)})`,
    },
    {
      title: 'Complément',
      dataIndex: 'complement',
      key: 'complement',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: 'GLN',
      dataIndex: 'gln',
      key: 'gln',
      width: 110,
      render: (el) => renderNull(el),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 110,
      className: currentDestinataire.destinataire
        ? 'block flex flex-col justify-center items-center'
        : 'hidden bg-transparent',
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.ADDRESSE}
          handleCheckedRadio={() =>
            handleAttDestinataire(TableType.ADDRESSE, el)
          }
        />
      ),
    },
  ]

  const columnsContact: ColumnsType<Contact> = [
    {
      title: 'Contact',
      dataIndex: 'nom',
      key: 'contact',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: 'Tel',
      dataIndex: 'tel',
      key: 'tel',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 225,
      render: (el) => renderNull(el),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 110,
      className: currentDestinataire.destinataire
        ? 'block flex flex-col justify-center items-center'
        : 'hidden bg-transparent',
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.CONTACT}
          handleCheckedRadio={() =>
            handleAttDestinataire(TableType.CONTACT, el)
          }
        />
      ),
    },
  ]

  return (
    <div>
      {isCreateDestinataire ? (
        <FormCreation
          type={FormCreationType.DESTINATAIRE}
          isCreateForm={isCreateDestinataire}
          onCloseForm={() => setIsCreateDestinataire(false)}
        />
      ) : (
        <SectionLayout
          title={FormCreationType.DESTINATAIRE}
          action={
            <Action
              onClickPlusBtn={() => {
                setIsCreateDestinataire(true)
                focusById(`${FormCreationType.DESTINATAIRE}-nom`, 300)
              }}
            />
          }
        >
          <div className="flex" ref={goToEmptyDestinataire}>
            <Form.Item
              label="Code & Nom Destinataire"
              colon={false}
              name="destinataire"
              validateStatus={isEmptyDestinataire ? 'error' : undefined}
            >
              <CustomSearch
                disabled={isDisabled}
                onChange={onChangeDestinataire}
                functionSearch={destinataireApi.searchDestinataireByNameOrCode}
                className="commande-custom-search"
                id={commandeKey['code-nom-destinataire']}
                previousId={`${commandeKey['Imperatif']}, ${commandeKey['envoyer-mission']}, ${commandeKey['Forcer-la-DLUO']}`}
              />
            </Form.Item>
            {(displayRelay || form.getFieldValue('relay_detail')) && (
              <Form.Item
                label="Relay"
                name="relay_detail"
                colon={false}
                className="ml-10"
              >
                <Input className="ml-3" maxLength={15} style={{ height: 25 }} />
              </Form.Item>
            )}
          </div>
          <div className="flex justify-between">
            {(currentDestinataire.destinataire ||
              (dataDestinataireAdresses.length >= 1 &&
                dataDestinataireContact.length >= 1 &&
                !currentDestinataire.destinataire)) && (
              <>
                <Table
                  rowKey="id"
                  bordered
                  columns={columnsAdresses}
                  loading={currentDestinataire.status === StatusAPI.calling}
                  dataSource={dataDestinataireAdresses}
                  className="custom-common-modal mr-12"
                  pagination={false}
                />
                <Table
                  rowKey="id"
                  bordered
                  columns={columnsContact}
                  loading={currentDestinataire.status === StatusAPI.calling}
                  dataSource={dataDestinataireContact}
                  className="custom-common-modal"
                  pagination={false}
                />
              </>
            )}
          </div>
        </SectionLayout>
      )}
    </div>
  )
}

export default FormDestination
