import { FC, Ref } from 'react'
import FormCreation from '../../../../components/Common/ModalDetails/FormCreation'
import { FormCreationType } from '../../../../enum'
import SectionLayout from '../../stockscreen/component/SectionLayout'
import { Form, Input } from 'antd'
import CustomSearch from '../../../../components/Common/CustomSearch/CustomSearch'
import transporteurApi from '../../../../http/transporteurApi'
import dayjs from 'dayjs'
import moment from 'moment/moment'
import { NameAndValue } from '../../stockscreen/component/Wrapper'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import CSwitch from '../../../../components/Common/CSwitch/CSwitch'
import { focusById } from '../../../../hook/usePressTab'
import CDatePicker from '../../../../components/Common/CDatePicker'

interface ActionProps {
  onClickPlusBtn?(): void

  isShowIconPlus?: boolean
}

export const Action = ({
  isShowIconPlus = true,
  onClickPlusBtn = () => {},
}: ActionProps) => {
  const onClickPlus = (): void => {
    if (onClickPlusBtn) onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      {isShowIconPlus && (
        <i className={'icon-add-plus'} onClick={onClickPlus}></i>
      )}
    </div>
  )
}

const FormLivraison: FC<{
  isCreateTransporteur: boolean
  setIsCreateTransporteur: (val: boolean) => void
  goToEmptyTransporteur?: Ref<any>
  isEmptyTransporteur: boolean
  onChangeTransporteur: (val: NameAndValue, code: string) => void
  gln: string
  form: any
  isDisabled?: boolean
  detailLength: number
}> = ({
  isCreateTransporteur,
  setIsCreateTransporteur,
  goToEmptyTransporteur,
  isEmptyTransporteur,
  onChangeTransporteur,
  gln,
  form,
  isDisabled = false,
  detailLength,
}) => {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  return (
    <div>
      {isCreateTransporteur ? (
        <FormCreation
          type={FormCreationType.LIVRAISON}
          isCreateForm={isCreateTransporteur}
          onCloseForm={() => setIsCreateTransporteur(false)}
        />
      ) : (
        <SectionLayout
          title={FormCreationType.LIVRAISON}
          action={
            <Action
              onClickPlusBtn={() => {
                setIsCreateTransporteur(true)
                focusById(`${FormCreationType.LIVRAISON}-nom`, 300)
              }}
            />
          }
        >
          <div
            style={{ width: '65%' }}
            ref={goToEmptyTransporteur}
            className="search-section"
          >
            <Form.Item
              label="Code & Nom Transporteur"
              colon={false}
              name="transporteur"
              validateStatus={isEmptyTransporteur ? 'error' : undefined}
            >
              <CustomSearch
                onChange={onChangeTransporteur}
                functionSearch={transporteurApi.searchTransporteurByNameOrCode}
                className="commande-custom-search"
                type={FormCreationType.LIVRAISON}
                disabled={isDisabled}
                id={commandeKey['code-nom-transporter']}
                previousId={`commandeicon-delete-${
                  detailLength > 0 ? detailLength - 1 : 0
                }, ${commandeKey['envoyer-mission']}, ${
                  commandeKey['photos']
                }, ${commandeKey['Forcer-la-DLUO']}`}
                nextId={commandeKey['Chargement-livraison']}
              />
            </Form.Item>
            <div className="flex justify-between">
              {gln && (
                <Form.Item label="GLN" colon={false}>
                  <Input
                    disabled
                    value={gln}
                    placeholder={gln}
                    className="ml-3"
                  />
                </Form.Item>
              )}
            </div>
            <div className="flex justify-between">
              <Form.Item label="Chargement" name="chargement_at" colon={false}>
                <CDatePicker
                  disabled={isDisabled}
                  placement="topLeft"
                  style={{ width: 156 }}
                  className="ml-3"
                  disabledDate={(current) => {
                    let livraisonDate = form.getFieldValue('livraison_at')
                    if (livraisonDate) {
                      return (
                        current &&
                        livraisonDate &&
                        (current > dayjs(livraisonDate).endOf('day') ||
                          current < moment().startOf('day'))
                      )
                    }
                    return current && current < moment().startOf('day')
                  }}
                  id={commandeKey['Chargement-livraison']}
                  data-previous-id={commandeKey['code-nom-transporter']}
                  data-next-id={commandeKey['Livraison-livraison']}
                />
              </Form.Item>
              <Form.Item label="Livraison" name="livraison_at" colon={false}>
                <CDatePicker
                  disabled={isDisabled}
                  placement="topLeft"
                  style={{ width: 156 }}
                  className="ml-3"
                  disabledDate={(current) => {
                    let chargementDate = form.getFieldValue('chargement_at')
                    let status =
                      current &&
                      chargementDate &&
                      current < dayjs(chargementDate).startOf('day')

                    if (chargementDate) {
                      return status
                    }

                    return current && current < moment().startOf('day')
                  }}
                  id={commandeKey['Livraison-livraison']}
                  data-previous-id={commandeKey['Chargement-livraison']}
                  data-next-id={commandeKey['Imperatif']}
                />
              </Form.Item>
              <Form.Item
                label="Impératif"
                name="imperatif"
                colon={false}
                valuePropName="checked"
              >
                <CSwitch
                  disabled={isDisabled}
                  className="ml-5 mr-10 custom-switch"
                  id={commandeKey['Imperatif']}
                  previousId={commandeKey['Livraison-livraison']}
                  nextId={commandeKey['code-nom-destinataire']}
                />
              </Form.Item>
            </div>
          </div>
        </SectionLayout>
      )}
    </div>
  )
}

export default FormLivraison
